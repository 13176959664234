@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: monospace
}

span {
  font-family: monospace;
}

.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  transition: transform 0.3s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.gradient-text {
  background: linear-gradient(2deg, #eab308, #db2777, #3b0764); 
  background-clip: text; 
  -webkit-background-clip: text; 
  color: transparent; 
}

.border-gradient {
  border-image: linear-gradient(90deg, #eab308, #db2777, #3b0764) 1; 
  border-image-slice: 2;
}

::-webkit-scrollbar {
  background-color: #3b0764;
  width: 8px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(2deg, #eab308, #db2777, #3b0764);
  border-radius: 10px; /* Borda arredondada do polegar */
}

::-webkit-scrollbar-track {
  background-color: #130220; /* Cor da trilha (track) */
  border-radius: 10px; /* Borda arredondada da trilha */
}